<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>اضافة إداري جديدة </CCardHeader>
        <CCardBody>
          <CForm>
            <CRow class="mt-3">
              <CCol sm="12" md="6">
                <CInput
                  invalid
                  description=""
                  label="الاسم"
                  horizontal
                  autocomplete="name"
                  v-model.trim="form.name"
                  invalid-feedback="يوجد خطأ في هذا الحقل"
                  :is-valid="!$v.form.name.$anyError"
                />
              </CCol>
            </CRow>

            <CRow class="mt-3">
              <CCol sm="12" md="6">
                <CInput
                  invalid
                  description=""
                  label="اسم المستخدم"
                  horizontal
                  autocomplete="username"
                  v-model.trim="form.username"
                  invalid-feedback="يوجد خطأ في هذا الحقل"
                  :is-valid="!$v.form.username.$anyError"
                />
              </CCol>
            </CRow>

            <CRow class="mt-3">
              <CCol sm="12" md="6">
                <CInput
                  type="password"
                  invalid
                  description=""
                  label="كلمة المرور"
                  horizontal
                  autocomplete="password"
                  v-model.trim="form.password"
                  invalid-feedback="يوجد خطأ في هذا الحقل"
                  :is-valid="!$v.form.password.$anyError"
                />
              </CCol>
            </CRow>
            
            <CRow class="mt-3">
              <CCol sm="12" md="6">
                <CInput
                  type="password"
                  invalid
                  description=""
                  label="تأكيد كلمة المرور"
                  horizontal
                  autocomplete="password"
                  v-model.trim="form.password_confirmation"
                  invalid-feedback="يوجد خطأ في هذا الحقل"
                  :is-valid="!$v.form.password_confirmation.$anyError"
                />
              </CCol>
            </CRow>

            <CRow class="mt-3">
              <CCol sm="12" md="6">
                <CInput
                  description=""
                  label="رقم الموبايل"
                  horizontal
                  autocomplete="phone"
                  v-model.trim="form.mobile"
                  invalid-feedback="يوجد خطأ في هذا الحقل"
                  :is-valid="!$v.form.mobile.$anyError"
                />
              </CCol>
            </CRow>

            <CRow class="mt-3">
              <CCol sm="12" md="6">
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3"> حالة الاداري </label>
                  <CCol sm="9">
                    <div role="group" class="form-check form-check-inline">
                      <label>
                        <input
                          type="radio"
                          name="category_id"
                          class="form-check-input"
                          value="1"
                          v-model="form.is_active"
                        />
                        نشط
                      </label>
                    </div>

                    <div role="group" class="form-check form-check-inline">
                      <label>
                        <input
                          type="radio"
                          name="category_id"
                          class="form-check-input"
                          value="0"
                          v-model="form.is_active"
                        />
                        متوقف
                      </label>
                    </div>
                  </CCol>
                </div>
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
        <CCardFooter>
          <CButton
            :disabled="loading"
            @click="submit"
            type="button"
            color="success"
          >
            <CSpinner v-if="loading" color="white" size="sm" />
            <span v-else> <CIcon name="cil-save" /> حفظ </span>
          </CButton>
        </CCardFooter>
        <CCardFooter v-if="formErrors">
          <CAlert color="danger">
            <CListGroup flush>
              <CListGroupItem v-for="(err, index) in formErrors" :key="index">
                {{ err[0] }}
              </CListGroupItem>
            </CListGroup>
          </CAlert>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
export default {
  name: "Create",
  data() {
    return {
      loading: false,
      form: {
        name: "",
        username: "",
        password: "",
        password_confirmation: "",
        mobile: "",
        is_active: 1,
      },
      formErrors: null,
    };
  },
  validations: {
    form: {
      name: { required, min: minLength(2) },
      username: { required, min: minLength(3) },
      password: { required, min: minLength(6) },
      password_confirmation: { required, min: minLength(6) },
      mobile: { required, min: minLength(10) },
    },
  },
  created() {},
  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return true;
      }
      this.loading = true;
      this.$http
        .post(`/admins/create`, this.form)
        .then((res) => {
          // handle success
          if (res.data.status == 200) {
            this.$router.push({ name: "Admins" });
          }
          this.loading = false;
        })
        .catch((error) => {
          // handle error
          if ((error.response.data.status == 422)) {
            this.formErrors = error.response.data.error.errors;
          }
          this.loading = false;
        });
    }
  },
};
</script>
